<template>
  <div>
    <v-row
      justify="center"
    >
      <v-col
        sm="12"
        md="12"
        lg="12"
      >
        <v-card-text class="text--primary">
          <div
            class="text-left"
            style="width:100%;"
          >
            <p> {{ quiz.data.longDescription }}</p>
            <v-divider />

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-comment-text-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>{{ $i18n.t('$l.quizzes.testQuiz.thisTestContains') + ' '+ selectedQuestions.length + ' ' + $i18n.t('$l.quizzes.testQuiz.questions2') }} </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-timer-sand</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>{{ $i18n.t('$l.quizzes.testQuiz.timeToCompleteTest') + ' ' + quiz.timeLimit + ' ' + $i18n.t('$l.quizzes.testQuiz.minutes') }} </v-list-item-subtitle>
            </v-list-item>

            <p
              :v-show="quiz.data.educationMaterialLink != null"
            >
              {{ $i18n.t('$l.quizzes.testQuiz.educationMaterials') }}
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-book-open</v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle>
                  <a
                    text
                    @click="openLink"
                  >
                    {{ quiz.data.educationMaterialLink }}
                  </a>
                </v-list-item-subtitle>
              </v-list-item>
            </p>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-start">
          <v-row>
            <v-col class="text-start">
              <v-btn
                class="ml-3"
                text
                elevation="0"
                @click="backFromTest()"
              >
                {{ $i18n.t('$l.app.back') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-col class="text-end">
            <v-btn
              class="primary ml-3"
              elevation="0"
              @click="startTest()"
            >
              {{ $i18n.t('$l.quizzes.testQuiz.startTest') }}
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'QuizInfo',
  props: {

    quiz: {
      type: Object,
      required: true,
      default: null

    },
    selectedQuestions: {
      type: Array,
      required: true,
      default: null
    }
  },
  methods: {
    openLink () {
      window.open(this.quiz.data.educationMaterialLink, '_blank')
    },
    startTest () {
      this.$emit('updateQuestionIndex', 0)
    },
    backFromTest () {
      this.$emit('back-from-test')
    }
  }
}

</script>
