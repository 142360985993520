<template>
  <v-container
    fluid
    class="container d-flex justify-center align-start flex-column"
  >
    <basic-card
      :title="quiz ? quiz.name : ''"
      width="800"
      class="mx-auto"
    >
      <app-api-call
        class="ma-0 mt-4"
        :call-result="callResult"
      />
      <QuizInfo
        v-if="questions && questionIndex==null"
        :quiz="quiz"
        :selected-questions="questions"
        @back-from-test="backFromTest"
        @updateQuestionIndex="updateQuestionIndex"
      />
      <QuizRunning
        v-else-if="questions && questionIndex!=null && questionIndex<questions.length"
        :quiz="quiz"
        :selected-questions="questions"
        :question-index="questionIndex"
        :user-responses="userResponses"
        @response-click="responseClick"
        @get-score="getScore"
        @updateQuestionIndex="updateQuestionIndex"
      />
      <QuizResult
        v-if="score!=null && questions && questionIndex!=null && questions.length>0 && questionIndex >= questions.length"
        :score="score"
        :minimal-pass-score="4"
        :selected-questions="questions"
        :question-index="questionIndex"
        :quiz="quiz"
        @init="init"
        @updateQuestionIndex="updateQuestionIndex"
        @back-from-test="backFromTest"
      />
    </basic-card>
  </v-container>
</template>

<script>
import QuizInfo from './QuizInfo'
import QuizRunning from './QuizRunning'
import QuizResult from './QuizResult'

export default {
  name: 'QuizWrapper',
  components: {
    QuizInfo,
    QuizRunning,
    QuizResult
  },

  props: {
    administrator: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      componentKey: 'str',
      showTemplate: false,
      quiz: null,
      questionIndex: null,
      userResponses: null,
      isActive: false,
      callResult: { finished: true, authorized: false, error: null, info: null },
      score: null,
      questions: null
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.componentKey = new Date().toString
      this.callResult.finished = false
      this.callResult.error = null
      this.$xapi.get(`education/quiz/${this.$route.params.quizId}`)
        .then(r => {
          if (r.data) {
            this.dataRcieved(r.data)
          }
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
        })
    },
    getScore () {
      this.callResult.finished = false
      this.$xapi.post(`education/quizresults/` + this.quiz.id, this.userResponses)
        .then(r => {
          if (r.data >= 0) {
            this.score = r.data
          }
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
        })
    },
    dataRcieved (quiz) {
      this.quiz = quiz
      this.quiz.data = JSON.parse(quiz.data)
      this.questions = this.selectRandomQuestions(this.quiz.data.questions)
      this.changeResponseOrder(this.questions)
      this.userResponses = Array(this.questions.length).fill()
      for (let i = 0; i < this.questions.length; i++) {
        this.userResponses[i] = {
          id: this.questions[i].id,
          responses: []
        }
      }
    },
    selectRandomQuestions (questions) {
      var newQuestion = []
      if (this.quiz.showQuestions <= questions.length) {
        for (let index = 0; index < this.quiz.showQuestions; index++) {
          var randomIndex = Math.floor(Math.random() * Math.floor(questions.length))
          newQuestion.push(questions[randomIndex])
          questions.splice(randomIndex, 1)
        }
      }
      return newQuestion
    },

    changeResponseOrder (questions) {
      for (let i = 0; i < questions.length; i++) {
        var responses = questions[i].responses
        var controller = false
        while (!controller) {
          responses.sort(() => Math.random() - 0.5)
          for (let k = 0; k < responses.length; k++) {
            var fixedOrderCounter = 0
            for (let x = 0; x < responses.length; x++) {
              if (responses[x].fixedOrder) {
                fixedOrderCounter++
              }
            }
            if (fixedOrderCounter === 0) {
              // return // nie je nutne menit poradia pre fixed order
            }
            if (responses[k].fixedOrder === true) {
              var fixedId = responses[k].id
              if (fixedId !== k) {
                var p = responses[fixedId]
                responses[fixedId] = responses[k]
                responses[k] = p
              }
            } else {
              for (let m = 0; m < responses.length; m++) {
                if (m !== k) {
                  if (responses[m].id === k && responses[m].fixedOrder) {
                    var q = responses[k]
                    responses[k] = responses[m]
                    responses[m] = q
                  }
                }
              }
            }
          }
          var controlCounter = 0
          for (let c = 0; c < responses.length; c++) {
            if ((responses[c].fixedOrder === true) && (responses[c].id === c + '')) {
              controlCounter++
            }
          }

          if (fixedOrderCounter === controlCounter) {
            controller = true
          } else {
            controller = false
          }
        }
      }
    },
    backFromTest () {
      if (this.administrator) {
        this.$router.push('/administration')
      } else {
        this.$router.push('/examination')
      }
    },
    responseClick (questionIdx, answerIdx) {
      var questions = this.questions
      var question = questions[questionIdx]
      var response = this.userResponses[questionIdx]
      if (question.type === 'SINGLE') {
        response = {
          id: question.id,
          responses: [{
            id: question.responses[answerIdx].id,
            correct: true
          }]
        }
      } else if (question.type === 'MULTIPLE') {
        var answerExists = false
        for (let i = 0; i < response.responses.length; i++) {
          if (response.responses[i].id === question.responses[answerIdx].id) {
            answerExists = true
            response.responses[i].correct = !response.responses[i].correct
          }
        }
        if (!answerExists) {
          response.responses.push({
            id: question.responses[answerIdx].id,
            correct: true
          })
        }
      }
      this.userResponses[questionIdx] = response
      this.userResponses = JSON.parse(JSON.stringify(this.userResponses))
    },
    getQuestionAlphaIndex (i) {
      return String.fromCharCode(97 + i)
    },
    updateQuestionIndex (newData) {
      this.questionIndex = newData

      if (this.questionIndex === this.questions.length) {
        this.getScore()
      }
    }
  }
}
</script>
