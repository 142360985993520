<template>
  <div>
    <v-progress-circular
      :rotate="-90"
      :size="40"
      :width="7"
      :value="percentageValue"
      color="light-green"
    >
      {{ parseInt(min) }}:{{ sec }}
    </v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'QuizTimer',
  props: {
    minutes: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      interval: {},
      value: 150
    }
  },
  computed: {
    min () {
      return this.value / 60
    },
    sec () {
      var result = (this.value % 60)
      return result < 10 ? '0' + result : result
    },
    percentageValue () {
      return (this.value * 100) / this.minutes
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  created () {
    this.value = this.minutes
    this.interval = setInterval(() => {
      if (this.value === 0) {
        clearInterval(this.interval)
        this.$emit('times-up')
      } else {
        this.value -= 1
      }
    }, 1000)
  }
}
</script>

<style scoped>
  .v-progress-circular {
    margin: 1rem;
  }
</style>
