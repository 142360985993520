<template>
  <div>
    <v-card-title>
      <div
        class="text-center"
        style="width:100%;"
      >
        <div
          class="d-flex flex-row"
        >
          <v-progress-linear
            v-model="knowledge"
            height="25"
            class="ma-0 mt-10"
          />
          <QuizTimer
            class="quizTimer"
            :minutes="quiz.timeLimit*60"
            @times-up="timesUp()"
          />
        </div>
        <p>
          {{ Math.round(knowledge) }}% {{ $i18n.t('$l.quizzes.testQuiz.completed') }}
        </p>
      </div>
    </v-card-title>

    <v-card-title
      class="text-center"
    >
      <div
        style="width:100%;"
      >
        {{ questionIndex+1 }}. {{ $i18n.t(selectedQuestions[questionIndex].text) }}
      </div>
      <div
        v-show="checkIfMultiple()"
        style="width:100%;"
        class="subtitle-1"
      >
        {{ $i18n.t('$l.quizzes.testQuiz.questionCanContainsSeveralCorrectAnswers') }}
      </div>
    </v-card-title>

    <v-card-title>
      <div

        style="width:100%;"
      >
        <div
          v-if="selectedQuestions[questionIndex]"
          class="optionContainer"
        >
          <div
            v-for="(response, index) in selectedQuestions[questionIndex].responses"
            :key="index"

            class="option"
            :class="{'is-seleted' : getResponseValue(questionIndex, response.id)}"
            @click="responseClick(questionIndex, index)"
          >
            <p
              v-if="response"
            >
              {{ index | charIndex }}. {{ response.text }}
            </p>
          </div>
        </div>
      </div>
    </v-card-title>
    <v-divider />
    <v-card-actions>
      <v-btn
        elevation="0"
        :disabled="questionIndex===0"
        @click="prev()"
      >
        <v-icon>
          mdi-arrow-left-bold-outline
        </v-icon>
        {{ $i18n.t('$l.app.back') }}
      </v-btn>
      <v-spacer />
      <v-btn
        class="primary ml-3"
        elevation="0"
        @click="next()"
      >
        {{ $i18n.t('$l.app.next') }}
        <v-icon>
          mdi-arrow-right-bold-outline
        </v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import QuizTimer from './QuizTimer'

export default {
  name: 'QuizRunning',
  components: {
    QuizTimer
  },
  filters: {
    charIndex (i) {
      return String.fromCharCode(97 + i)
    }
  },
  props: {
    quiz: {
      type: Object,
      required: true
    },
    selectedQuestions: {
      type: Array,
      required: false,
      default: null
    },
    questionIndex: {
      type: Number,
      required: true
    },
    userResponses: {
      type: Array,
      required: true
    }
  },
  computed: {
    knowledge () {
      return (this.questionIndex / this.selectedQuestions.length) * 100
    }
  },
  methods: {
    timesUp () {
      this.$emit('updateQuestionIndex', this.selectedQuestions.length)
      this.getScore()
    },
    next () {
      if (this.questionIndex < this.selectedQuestions.length) {
        var idx = this.questionIndex
        idx++
        this.$emit('updateQuestionIndex', idx)
      }
    },
    getResponseValue (questionIndex, responseId) {
      var userRespons = this.userResponses[questionIndex]
      for (let index = 0; index < userRespons.responses.length; index++) {
        if (userRespons.responses[index].id === responseId) {
          return userRespons.responses[index].correct
        }
      }
      return false
    },

    prev () {
      if (this.selectedQuestions.length > 0 && this.questionIndex > 0) {
        var idx = this.questionIndex
        idx--
        this.$emit('updateQuestionIndex', idx)
      }
    },
    checkIfMultiple () {
      if (this.selectedQuestions[this.questionIndex].type === 'MULTIPLE') {
        return true
      } else {
        return false
      }
    },
    responseClick (questionIndex, index) {
      this.$emit('response-click', questionIndex, index)
    },
    getScore () {
      this.$emit('get-score')
    }
  }

}
</script>

<style lang="scss" scoped>
  $trans_duration: 0.3s;
  .optionContainer {
      margin-top: 12px;
      flex-grow: 1;
      .option {
        border-radius: 290486px;
        padding: 9px 18px;
        margin: 0 18px;
        margin-bottom: 12px;
        transition: $trans_duration;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.05);
        border: transparent 1px solid;

        &.is-selected {
            color: rgba(0,0,0,0.85);
            border-color: rgba(black,0.25);
            background-color: white !important;
        }
        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
        &:active {
            transform: scaleX(0.9);
        }
      }
      .option.is-selected {
            border-color: rgba(black,0.25);
            background-color: white !important;
        }
  }
  .option.is-seleted {
    color: rgba(0,0,0,0.85);
    border-color: rgba(black,0.25);
    background-color: white !important;
  }
</style>
