<template>
  <QuizWrapper />
</template>

<script>
import QuizWrapper from '@/components/education/QuizWrapper'

export default {
  name: 'Program',
  components: {
    QuizWrapper
  }
}
</script>
