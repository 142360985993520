<template>
  <div>
    <v-card-title>
      <div
        class="text-center"
        style="width:100%;"
      >
        {{ getResultTitle() }}
      </div>
    </v-card-title>

    <v-card-title
      class="d-flex justify-center text-h1"
    >
      {{ Math.round(percentageScore) }}%
    </v-card-title>

    <v-card-title>
      <div
        class="text-center"
        style="width:100%;"
      >
        {{ $i18n.t('$l.quiz.results.rightAnswers') +' '+score +' '+$i18n.t('$l.quiz.results.answersOf')+' '+ selectedQuestions.length }}.
        <br>
        {{ $i18n.t('$l.quiz.results.minimalPassScore') +' '+ quiz.scoreToPass }}.
        <br>
      </div>
    </v-card-title>
    <br>
    <v-card-actions
      class="d-flex justify-center"
    >
      <v-btn
        class="ma-4"
        @click="backFromTest()"
      >
        {{ $i18n.t('$l.quizzes.testQuiz.finish') }}
        <v-icon dark>
          mdi-check
        </v-icon>
      </v-btn>
      <v-btn
        class="ma-4"
        @click="restart()"
      >
        {{ $i18n.t('$l.quizzes.testQuiz.restart') }}
        <v-icon dark>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  name: 'QuizResult',
  props: {
    quiz: {
      type: Object,
      required: true
    },
    selectedQuestions: {
      type: Array,
      required: true
    },
    questionIndex: {
      type: Number,
      required: true
    },
    score: {
      type: Number,
      default: 0
    }
  },
  computed: {
    percentageScore () {
      return (this.score / this.selectedQuestions.length) * 100
    }
  },
  methods: {
    restart () {
      this.$emit('updateQuestionIndex', 0)
      this.$emit('init')
    },
    getResultTitle () {
      return this.score >= this.quiz.scoreToPass ? this.$i18n.t('$l.quiz.results.quizPassText') : this.$i18n.t('$l.quiz.results.quizNotPassText')
    },
    backFromTest () {
      this.$emit('back-from-test')
    }
  }
}
</script>
